import { useUser, withPageAuthRequired } from '@auth0/nextjs-auth0';
import { Col, Row, Skeleton, Table, Timeline, Tooltip, Typography } from 'antd';
import { format } from 'date-fns';
import { NextPage } from 'next';
import Head from 'next/head';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useState } from 'react';

import { generateEventData, identify } from '@/analytics';
import { DashboardViewed, dashboardViewed } from '@/analytics/segment';
import formatCurrency from '@/components/common/Extensions/CurrencyExtensions';
import { useGetReupProductsQuery } from '@/graphql/hooks';
import { License, Reup_Product } from '@/graphql/types';
import { useGetBuyerLicenseByLicenseNumber } from '@/lib/hooks';
import { Reorder } from '@/types';

import formatDate from '../../components/common/Extensions/DateExtensions';
import { reorderFromProduct } from './reorder-assistant';

interface TimelineEvent {
  HappenedAt: string;
  SupplierName: string;
  PurchaseOrderStatus: string;
  PurchaseOrderNumber: string;
}

const getReorders = (products: Reup_Product[]): Reorder[] => {
  const reorders = products.map((p) => reorderFromProduct(p, 'w2', 0)); // ToDo: Maybe pull in PO data?
  return reorders;
};

const renderTooltip = () => {
  return (
    <>
      <p>
        The Estimated Stockout date is based on sales data from the last 2
        weeks. You can edit this on the ReOrder Assistant.
      </p>
      <br />
      <p>Let us know if you’d like any other customizations!</p>
    </>
  );
};

const OrgLandingPage: NextPage = () => {
  const timeline: TimelineEvent[] = [];
  const [licenseNumber, setLicenseNumber] = useState('');
  const [storeLicense, setStoreLicense] = useState({} as License);
  const [trackingComplete, setTrackingComplete] = useState(false);

  const { user } = useUser();
  const {
    query: { org, store, pathName },
  } = useRouter();

  const orgkey = org as string;
  const storekey = store as string;

  // ToDo: Validate Org
  // const { orgExists } = useOrgValidation();
  const { data, isFetched, isFetching } = useGetReupProductsQuery({
    orgkey,
    storekey,
    limit: 25,
  });

  const { license, licensesFetched } =
    useGetBuyerLicenseByLicenseNumber(licenseNumber);

  useEffect(() => {
    if (isFetched && data?.products) {
      setLicenseNumber(data?.products[0].LicenseNum as string);
    }

    if (license && licensesFetched) {
      setStoreLicense(license);
    }
  }, [isFetched, data?.products, license, licensesFetched]);

  const dashboardViewEvent = useCallback(() => {
    dashboardViewed(
      generateEventData(
        orgkey,
        storekey,
        storeLicense.licenseNumber,
        storeLicense.state,
        {
          email: user?.email,
        }
      ) as DashboardViewed
    );

    identify({
      CurrentAccountLink: pathName as string,
      license: {
        licenseNumber: storeLicense.licenseNumber,
        licenseName: storeLicense.licenseName,
      },
      Email: user?.email as string,
      Name: user?.name as string,
      Product: 'Reup',
    });
  }, [
    orgkey,
    storekey,
    storeLicense.licenseNumber,
    storeLicense.state,
    storeLicense.licenseName,
    user?.email,
    user?.name,
    pathName,
  ]);

  useEffect(() => {
    if (licensesFetched && storeLicense.licenseNumber && !trackingComplete) {
      dashboardViewEvent();
      setTrackingComplete(true);
    }
  }, [
    dashboardViewEvent,
    licensesFetched,
    storeLicense.licenseNumber,
    trackingComplete,
  ]);

  const columns = [
    {
      title: 'Product Name',
      dataIndex: 'productName',
      key: 'productName',
    },
    {
      title: 'Available',
      dataIndex: 'quantityOnHand',
      key: 'quantityOnHand',
    },
    {
      title: 'Est. Sellout',
      dataIndex: 'stockOutDate',
      key: 'stockOutDate',
      sorter: (a: Reorder, b: Reorder) =>
        a.stockOutDate.getTime() - b.stockOutDate.getTime(),
      render: (value: Date) => format(value, 'iii, MMM d'),
    },
    {
      title: 'Estimated Loss/day',
      key: 'estimatedDailyLoss',
      dataIndex: 'estimatedDailyLoss',
      render: (amt: number) => <span>{formatCurrency(amt)}</span>,
      sorter: (a: Reorder, b: Reorder) =>
        a.estimatedDailyLoss && b.estimatedDailyLoss
          ? a.estimatedDailyLoss - b.estimatedDailyLoss
          : 0,
    },
  ];

  return (
    <div>
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1"
        />
      </Head>
      <Row gutter={16}>
        <Col span={18}>
          <div className="p-6 bg-white mb-6 border border-solid border-gray-300">
            <div className="mb-11">
              <Tooltip title={renderTooltip} getPopupContainer={(node) => node}>
                <h2 className="text-blue-700 w-fit">Low Stock Products</h2>
              </Tooltip>
              <h4>
                Reorder these top selling products before they run out!{' '}
                <Link href={`${orgkey}/reorder-assistant?store=${storekey}`}>
                  Reorder Assistant
                </Link>
              </h4>
            </div>
            <div>
              {isFetched && data?.products && (
                <Table
                  columns={columns}
                  dataSource={getReorders(data.products as Reup_Product[])}
                />
              )}
              {isFetching && <Skeleton active />}
            </div>
          </div>
        </Col>

        <Col span={6}>
          <div className="p-6 bg-white mb-6 border border-solid border-gray-300">
            <div className="mb-11">
              <Typography.Title level={5}>
                Recent Order Activity
              </Typography.Title>
            </div>
            <div hidden={timeline.length <= 0}>
              <Timeline mode="left">
                {timeline.map((tLine, i) => {
                  let timeLineState = '';
                  let statusMessage = '';
                  switch (tLine.PurchaseOrderStatus) {
                    case 'Draft':
                      timeLineState = 'gray';
                      statusMessage = 'draft created';
                      break;
                    case 'Placed':
                      timeLineState = 'blue';
                      statusMessage = 'placed';
                      break;
                    case 'Received':
                      timeLineState = 'green';
                      statusMessage = 'received at your facility';
                      break;
                    case 'Canceled':
                      timeLineState = 'red';
                      statusMessage = 'canceled';
                      break;
                    default:
                      timeLineState = 'black';
                      statusMessage = 'unknown';
                  }

                  return (
                    <Timeline.Item
                      // eslint-disable-next-line react/no-array-index-key
                      key={`${tLine.PurchaseOrderNumber}-${i}`}
                      color={timeLineState}
                      label={formatDate(tLine.HappenedAt)}
                    >
                      <p>{`${tLine.SupplierName} order ${tLine.PurchaseOrderNumber}`}</p>
                      <p>{statusMessage}</p>
                    </Timeline.Item>
                  );
                })}
              </Timeline>
            </div>
            <div hidden={timeline.length > 0}>
              <h3>NO DATA</h3>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default withPageAuthRequired(OrgLandingPage);
